import type { DocumentRendererProps } from '@keystone-6/document-renderer'

import Quote from './Quote'
import { Hero } from './Hero'
import { Image } from './Image'
import { Carousel } from './Carousel'
import { Callout } from './Callout/Callout'
import { Printable } from './Printable'
import { VideoAsset } from './VideoAsset'

interface CreateComponentBlockRenderersProps {
  targetBlank?: boolean
}

export const createComponentBlockRenderers = ({
  targetBlank
}: CreateComponentBlockRenderersProps): DocumentRendererProps['componentBlocks'] => ({
  textStyles: props => {
    return <div className={`text-${props.intent}`}>{props.content}</div>
  },
  image: props => {
    return <Image {...props} targetBlank={targetBlank} />
  },
  hero: props => {
    return <Hero {...props} targetBlank={targetBlank} />
  },
  callout: props => {
    return <Callout {...props} />
  },
  carousel: props => {
    return <Carousel {...props} />
  },
  quote: props => {
    return <Quote {...props} />
  },
  printables: props => {
    return <Printable {...props} />
  },
  videoAsset: props => {
    return <VideoAsset {...props} />
  }
})
