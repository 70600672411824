import MuxPlayerReact from '@mux/mux-video-react'
import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import type { ContentError } from '~/modules/auth/services/access'

import MaybeVideoSignUpWrapper from './MaybeVideoSignUpWrapper'

interface MuxPlayerProps {
  playbackId?: string
  videoToken?: string | null
  contentError?: ContentError | null
  thumbnail?: string
  nonce: string
  muxDataEnvKey?: string
  userId?: string
  videoAssetId?: string
  videoId?: string
  videoTitle?: string
  endImage?: string | null
  checkPermissions?: boolean
  config?: {
    autoplay?: boolean
    hideControls?: boolean
    loop?: boolean
    squareEdge?: boolean
    muted?: boolean
  }
  onEnd?: () => void
}

const MuxPlayer = memo<MuxPlayerProps>(
  ({
    playbackId,
    videoToken,
    contentError,
    thumbnail,
    nonce,
    muxDataEnvKey,
    userId,
    videoId,
    videoTitle,
    endImage,
    checkPermissions = true, // Default to false
    config,
    onEnd
  }) => {
    const [ended, setEnded] = useState(false)
    const playerRef = useRef<HTMLVideoElement>(null)
    const [inViewRef, inView] = useInView({
      rootMargin: '20px',
      threshold: 0.01,
      triggerOnce: true
    })

    useEffect(() => {
      if (playerRef.current && config?.autoplay) {
        if (inView) {
          playerRef.current.play()
        } else {
          playerRef.current.pause()
        }
      }
    }, [inView, config?.autoplay])

    const videoContent = (
      <div ref={inViewRef} className="relative">
        {ended && (
          <img
            src={endImage || thumbnail}
            className="pointer-events-none absolute inset-0 z-10 aspect-video w-full rounded-lg sm:rounded-xl"
            alt="End thumbnail"
          />
        )}
        <MuxPlayerReact
          key={playbackId}
          ref={playerRef}
          nonce={nonce}
          playbackId={videoToken ? `${playbackId}?token=${videoToken}` : undefined}
          poster={thumbnail}
          streamType="on-demand"
          envKey={muxDataEnvKey}
          metadata={{
            video_id: videoId,
            video_title: videoTitle,
            viewer_user_id: userId ?? 'Anonymous'
          }}
          preload="auto"
          autoPlay={config?.autoplay}
          loop={config?.loop}
          muted={config?.muted}
          controls={videoToken ? !config?.hideControls : false}
          className={classNames('aspect-video w-full', config?.squareEdge ? '' : 'rounded-lg sm:rounded-xl')}
          onEnded={() => {
            setEnded(true)
            if (endImage || thumbnail) {
              // Show the end image for 2 seconds before calling onEnd
              const timer = setTimeout(() => {
                onEnd?.()
              }, 2000)
              return () => clearTimeout(timer)
            } else {
              onEnd?.()
            }
          }}
          onPlay={() => {
            setEnded(false)
          }}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      </div>
    )

    return checkPermissions ? (
      <MaybeVideoSignUpWrapper videoTitle={videoTitle} videoToken={videoToken} contentError={contentError}>
        {videoContent}
      </MaybeVideoSignUpWrapper>
    ) : (
      videoContent
    )
  }
)

MuxPlayer.displayName = 'MuxPlayer'

export default MuxPlayer
