import MuxPlayer from '~/modules/videos/components/MuxPlayer'

interface VideoAssetProps {
  asset: {
    id: string
    label: string
    data: {
      id: string
      title: string
      playbackId: string
      playerUrls?: {
        video: string
        thumbnail: string
      }
      playerTokens?: {
        muxDataEnvKey: string
        nonce: string
        videoToken: string
      }
      customThumbnail?: {
        id: string
        name: string
        imageFile: {
          id: string
          url: string
        }
      }
    }
  }
}

export function VideoAsset({ asset }: VideoAssetProps) {
  return (
    <div className="flex flex-1 flex-col space-y-4">
      <MuxPlayer
        key={asset.data.playbackId}
        playbackId={asset.data.playbackId}
        thumbnail={asset.data.customThumbnail?.imageFile?.url || asset.data?.playerUrls?.thumbnail}
        videoId={asset.id}
        videoTitle={asset.label || asset.data.title || 'Unknown video'}
        checkPermissions={false}
        videoToken={asset.data?.playerTokens?.videoToken}
        muxDataEnvKey={asset.data?.playerTokens?.muxDataEnvKey}
        nonce={asset.data?.playerTokens?.nonce || ''}
      />
    </div>
  )
}
